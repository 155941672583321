import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import styled from 'styled-components'

import TypographyBlock from '~components/CmsBlock/TypographyBlock'
import ListItemBlock from '~components/CmsBlock/ListItemBlock'
import OrderedListBlock from '~components/CmsBlock/OrderedListBlock'
import UnorderedListBlock from '~components/CmsBlock/UnorderedListBlock'
import CustomBlock from '~components/CmsBlock/CustomBlock'
import LinkBlock from '~components/CmsBlock/LinkBlock'
import { media } from '~styles/mediaQuery'

const Wrapper = styled.div`
  ${media.mq1`
    padding-top: ${({ theme }) => theme.spacing[16]}};
  `};
  ${media.mq234`
    padding-top: ${({ theme }) => theme.spacing[32]}};
  `};
`

const options = (imageList, pdfList) => {
  return {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        if (node.data.uri.startsWith('/')) {
          return <LinkBlock to={node.data.uri}>{children}</LinkBlock>
        }
        return <LinkBlock href={node.data.uri}>{children}</LinkBlock>
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        return (
          <CustomBlock
            target={node.data.target}
            imageList={imageList}
            pdfList={pdfList}
          />
        )
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <TypographyBlock type="body2" as="p">
          {children}
        </TypographyBlock>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <TypographyBlock type="header1" as="h1">
          {children}
        </TypographyBlock>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <TypographyBlock type="header2" as="h2">
          {children}
        </TypographyBlock>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <TypographyBlock type="header3" as="h3">
          {children}
        </TypographyBlock>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <TypographyBlock type="header4" as="h4">
          {children}
        </TypographyBlock>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <TypographyBlock type="header5" as="h5">
          {children}
        </TypographyBlock>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedListBlock>{children}</UnorderedListBlock>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedListBlock>{children}</OrderedListBlock>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <ListItemBlock>{children}</ListItemBlock>
      ),
    },
  }
}

const PageBody = ({ content: { json, image: imageList, pdf: pdfList } }) => (
  <Wrapper>
    {documentToReactComponents(json, options(imageList, pdfList))}
  </Wrapper>
)

export default PageBody
