import React from 'react'
import Img from 'gatsby-image'

import { LOCALE } from '~utils/constants'
import { getGatsbyImageSrcSet, replaceHttpWithHttps } from '~utils/helpers'

const ImageBlock = ({ image: gatsbyImage, target }) => {
  const { altText, imageLarge: cloudinaryImage } = target.fields
  const { public_id: id, format, url: src } = cloudinaryImage[LOCALE][0]
  const { srcSet: orgSrcSet } = gatsbyImage[0].childImageSharp.fluid

  // override gatsby's src and srcSet with the one from cloudinary
  const fluid = {
    ...gatsbyImage[0].childImageSharp.fluid,
    srcSet: getGatsbyImageSrcSet({ orgSrcSet, id, format }),
    src: replaceHttpWithHttps(src),
  }

  return <Img fluid={fluid} alt={altText[LOCALE]} />
}

export default ImageBlock
