import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

import LinkExternal from '~components/LinkExternal'
import { LOCALE } from '~utils/constants'
import { getGatsbyImageSrcSet, replaceHttpWithHttps } from '~utils/helpers'

const ImageBlock = ({ image: gatsbyImage, target }) => {
  const { description: altText, pdfFile: cloudinaryImage } = target.fields
  const { public_id: id, format, url: src } = cloudinaryImage[LOCALE][0]
  const { srcSet: orgSrcSet } = gatsbyImage[0].childImageSharp.fluid

  // override gatsby's src and srcSet with the one from cloudinary
  const fluid = {
    ...gatsbyImage[0].childImageSharp.fluid,
    srcSet: getGatsbyImageSrcSet({ orgSrcSet, id, format }),
    src: replaceHttpWithHttps(src),
  }

  return <Img fluid={fluid} alt={altText[LOCALE]} />
}

const A = styled(LinkExternal)`
  max-width: 400px;
  display: block;
  margin: 3vw auto;
`

const PdfBlock = ({ pdfList, imageList, target }) => {
  return (
    <A
      href={pdfList[0].publicURL}
      hrefLang="de"
      aria-label="display-pdf"
      type="pdf"
    >
      <ImageBlock
        target={target}
        image={imageList.filter(
          (image) => image.id === target.fields.pdfFile[LOCALE][0].public_id,
        )}
      />
    </A>
  )
}

export default PdfBlock
