import styled from 'styled-components'

import Typography from '~components/Typography'
import { fluidSpacingFunc } from '~styles/helpers'

const ListItemBlock = styled(Typography).attrs(() => ({
  as: 'li',
}))`
  ${fluidSpacingFunc([{ property: 'margin', values: [0, 0, 1] }])};
`

export default ListItemBlock
