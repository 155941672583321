import styled from 'styled-components'

import ComponentsLinkExternal from '~components/LinkExternal'

import stylingLink from './stylingLink'

const LinkExternal = styled(ComponentsLinkExternal)`
  ${stylingLink};
`

export default LinkExternal
