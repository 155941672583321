import React from 'react'
import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import { LOCALE } from '~utils/constants'

import CustomBlock from './CustomBlock'

const Wrapper = styled.div`
  ${(props) =>
    props.gridCss &&
    Object.keys(props.gridCss).map((mediaQuery) =>
      mediaQuery === 'mq1234'
        ? props.gridCss.mq1234
        : media[mediaQuery]`${props.gridCss[mediaQuery]}
        `,
    )};
`

const GridBlock = ({ target, imageList }) => {
  return (
    <Wrapper>
      {target.fields.blocks[LOCALE].map((block) => (
        <CustomBlock key={block.sys.id} target={block} imageList={imageList} />
      ))}
    </Wrapper>
  )
}

export default GridBlock
