import { css } from 'styled-components'

import { transitions } from '~styles/constants'
import theme from '~styles/theme'

const stylingLink = css`
  /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
  transition: background ${transitions.duration.shorter}ms
    ${transitions.easing.easeIn};
  color: ${theme.palette.text.primary.main};
  text-decoration: none;
  background: no-repeat 0 90%
    linear-gradient(
      ${theme.palette.secondary.main} 0%,
      ${theme.palette.secondary.main} 100%
    );
  background-size: 100% 6px;
  padding: 0 ${theme.spacing[4]};

  &:hover {
    background-size: 100% 100%;
  }
`

export default stylingLink
