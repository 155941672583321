import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { fluidSpacingFunc } from '~styles/helpers'
import { LOCALE } from '~utils/constants'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  ${fluidSpacingFunc([{ property: 'margin', values: [3, 0] }])};
`

const Placeholder = styled.div`
  padding-top: 56.25%;
  background: black;
  opacity: 0.1;
`

const Video = styled.iframe`
  position: absolute;
  top: 0;
  width: 100%;
`

const YouTubeVideoBlock = ({ target }) => {
  const { description, videoId } = target.fields
  return (
    <Wrapper>
      <Placeholder />
      <Video
        title={`${description[LOCALE]} - YouTube video`}
        width={'100%'}
        height={'100%'}
        src={`https://www.youtube.com/embed/${videoId[LOCALE]}?rel=0&amp;showinfo=0`}
        frameBorder={'0'}
        allow={'autoplay; encrypted-media'}
        allowFullScreen
      />
    </Wrapper>
  )
}

YouTubeVideoBlock.propTypes = {
  target: PropTypes.object.isRequired,
}

export default YouTubeVideoBlock
