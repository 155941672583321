import React from 'react'
import styled from 'styled-components'

import Button from '~components/Button'
import { removeLinebreak } from '~utils/helpers'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => `${theme.spacing[32]} 0`};
`

const NextPost = styled(Button)`
  width: auto;
  display: block;
  white-space: nowrap;
`

const Title = styled.span`
  max-width: 30vw;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Icon = styled.span`
  ${({ position, theme }) => `margin-${position}: ${theme.spacing[8]}`}
`

const PostLinks = ({ previous, next }) => (
  <Wrapper>
    {previous && (
      <NextPost to={`/blog/${previous.slug}/`}>
        <Icon position="right">&#8592;</Icon>{' '}
        <Title>{removeLinebreak(previous.title)}</Title>
      </NextPost>
    )}
    {next && (
      <NextPost to={`/blog/${next.slug}/`}>
        <Title>{removeLinebreak(next.title)}</Title>{' '}
        <Icon position="left">&#8594;</Icon>
      </NextPost>
    )}
  </Wrapper>
)

export default PostLinks
