import React, { useEffect, useState, useCallback } from 'react'
import Img from 'gatsby-image'

import { LOCALE } from '~utils/constants'
import { breakpoint } from '~styles/mediaQuery'
import { getGatsbyImageSrcSet, replaceHttpWithHttps } from '~utils/helpers'
import useWindowSize from '~hooks/useWindowSize'

const HeroImage = ({ imageData }) => {
  const { altText, imageLarge, imageMedium, imageSmall } = imageData

  const { innerWidth: windowWidth } = useWindowSize()

  const getAspectRatio = useCallback(() => {
    if (imageSmall && windowWidth < breakpoint.breakpointSmall) {
      return imageSmall[0].image.childImageSharp.fluid.aspectRatio
    }
    if (imageMedium && windowWidth < breakpoint.breakpointMedium) {
      return imageMedium[0].image.childImageSharp.fluid.aspectRatio
    }
    return imageLarge[0].image.childImageSharp.fluid.aspectRatio
  }, [imageLarge, imageMedium, imageSmall, windowWidth])
  const [aspectRatio, setAspectRatio] = useState(getAspectRatio())

  useEffect(() => {
    setAspectRatio(getAspectRatio())
  }, [windowWidth, getAspectRatio])

  const imageList = [
    { ...imageLarge, media: `(min-width: ${breakpoint.breakpointSmall}px)` },
    ...[
      imageMedium === null
        ? null
        : {
            ...imageMedium,
            media: `(min-width: ${breakpoint.breakpointMedium}px)`,
          },
    ],
    imageSmall,
  ]

  const sources = imageList.flatMap((gatsbyImage) => {
    if (gatsbyImage === null) {
      return []
    }

    const { media } = gatsbyImage
    const { url: src, format, image } = gatsbyImage[0]
    const { srcSet: orgSrcSet } = image.childImageSharp.fluid

    // override gatsby's src and srcSet with the one from cloudinary
    return {
      ...image.childImageSharp.fluid,
      srcSet: getGatsbyImageSrcSet({ orgSrcSet, id: image.id, format }),
      media,
      src: replaceHttpWithHttps(src),
      aspectRatio,
    }
  })

  return <Img fluid={sources} alt={altText[LOCALE]} />
}

export default HeroImage
