import React from 'react'

import { LOCALE } from '~utils/constants'

import GridBlock from './GridBlock'
import YouTubeVideoBlock from './YouTubeVideoBlock'
import ImageBlock from './ImageBlock'
import TypographyBlock from './TypographyBlock'
import PdfBlock from './PdfBlock'

const PrepImageBlock = ({ target, imageList }) => {
  if (!imageList || !target.fields) {
    return null
  }
  return (
    <ImageBlock
      target={target}
      image={imageList.filter(
        (image) => image.id === target.fields.imageLarge[LOCALE][0].public_id,
      )}
    />
  )
}

const PrepareTypographyBlock = ({
  target: {
    fields: { text, htmlTag, style },
  },
}) => (
  <TypographyBlock type={style[LOCALE]} as={htmlTag[LOCALE]}>
    {text[LOCALE]}
  </TypographyBlock>
)

const CUSTOM_BLOCK = {
  // TODO lazy loading not needed blocks
  customBlockGrid: GridBlock,
  customBlockYoutubeVideo: YouTubeVideoBlock,
  cloudinaryImage: PrepImageBlock,
  customBlockTypography: PrepareTypographyBlock,
  customBlockPdf: PdfBlock,
}

const CustomBlock = ({ target, imageList, pdfList }) => {
  const { id } = target.sys.contentType.sys
  const BlockCustomComponent = CUSTOM_BLOCK[id]

  if (!BlockCustomComponent) {
    return null
  }

  return (
    <BlockCustomComponent
      target={target}
      imageList={imageList}
      pdfList={pdfList}
    />
  )
}

export default CustomBlock
