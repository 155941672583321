import React from 'react'
import PropTypes from 'prop-types'

import LinkInternal from './LinkInternal'
import LinkExternal from './LinkExternal'

const LinkBlock = ({ children, to, ...props }) =>
  to ? (
    <LinkInternal to={to} {...props}>
      {children}
    </LinkInternal>
  ) : (
    <LinkExternal target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </LinkExternal>
  )

LinkBlock.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
}

export default LinkBlock
