import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import { fluidSpacingFunc } from '~styles/helpers'
import Typography from '~components/Typography'

// const typeLookupMapOld = {
//   body1: '0 0 2rem',
//   body2: '0 0 1.5rem',
//   body3: '0 0 0.75rem',
//   body4: '0 0 0.125rem',
//   header1: '3rem 0 2rem',
//   header2: '2.5rem 0 1.5rem',
//   header3: '1rem 0 0.75rem',
//   header4: '0.5rem 0 0.25rem',
//   header5: '0.25 0 0.125rem',
//   instagram: '0',
// }

const typeLookupMapMq1 = {
  body1: fluidSpacingFunc([{ property: 'margin-bottom', values: [4] }]),
  body2: fluidSpacingFunc([{ property: 'margin-bottom', values: [2] }]),
  body3: fluidSpacingFunc([{ property: 'margin-bottom', values: [0.75] }]),
  body4: fluidSpacingFunc([{ property: 'margin-bottom', values: [0.25] }]),
  header1: fluidSpacingFunc([{ property: 'margin', values: [5.5, 0, 3] }]),
  header2: fluidSpacingFunc([{ property: 'margin', values: [4.5, 0, 2] }]),
  header3: fluidSpacingFunc([{ property: 'margin', values: [3.5, 0, 1.25] }]),
  header4: fluidSpacingFunc([{ property: 'margin', values: [1, 0, 0.5] }]),
  header5: fluidSpacingFunc([{ property: 'margin', values: [0.5, 0, 0.125] }]),
  instagram: '0',
}

// const typeLookupMapMq2 = {
//   body1: fluidSpacingFunc([{ property: 'margin-bottom', values: [4] }]),
//   body2: fluidSpacingFunc([{ property: 'margin-bottom', values: [2] }]),
//   body3: fluidSpacingFunc([{ property: 'margin-bottom', values: [1] }]),
//   body4: fluidSpacingFunc([{ property: 'margin-bottom', values: [0.5] }]),
//   header1: fluidSpacingFunc([{ property: 'margin', values: [5, 0, 3] }]),
//   header2: fluidSpacingFunc([{ property: 'margin', values: [4, 0, 3] }]),
//   header3: fluidSpacingFunc([{ property: 'margin', values: [3, 0, 2] }]),
//   header4: fluidSpacingFunc([{ property: 'margin', values: [1, 0, 0.5] }]),
//   header5: fluidSpacingFunc([{ property: 'margin', values: [0.5, 0, 0.125] }]),
//   instagram: '0',
// }

// const typeLookupMapMq3 = {
//   body1: fluidSpacingFunc([{ property: 'margin-bottom', values: [4] }]),
//   body2: fluidSpacingFunc([{ property: 'margin-bottom', values: [2] }]),
//   body3: fluidSpacingFunc([{ property: 'margin-bottom', values: [1] }]),
//   body4: fluidSpacingFunc([{ property: 'margin-bottom', values: [0.5] }]),
//   header1: fluidSpacingFunc([{ property: 'margin', values: [5, 0, 3] }]),
//   header2: fluidSpacingFunc([{ property: 'margin', values: [4, 0, 3] }]),
//   header3: fluidSpacingFunc([{ property: 'margin', values: [3, 0, 2] }]),
//   header4: fluidSpacingFunc([{ property: 'margin', values: [1, 0, 0.5] }]),
//   header5: fluidSpacingFunc([{ property: 'margin', values: [0.5, 0, 0.125] }]),
//   instagram: '0',
// }

// const typeLookupMapMq4 = {
//   body1: fluidSpacingFunc([{ property: 'margin-bottom', values: [4] }]),
//   body2: fluidSpacingFunc([{ property: 'margin-bottom', values: [2] }]),
//   body3: fluidSpacingFunc([{ property: 'margin-bottom', values: [1] }]),
//   body4: fluidSpacingFunc([{ property: 'margin-bottom', values: [0.5] }]),
//   header1: fluidSpacingFunc([{ property: 'margin', values: [5, 0, 3] }]),
//   header2: fluidSpacingFunc([{ property: 'margin', values: [4, 0, 3] }]),
//   header3: fluidSpacingFunc([{ property: 'margin', values: [3, 0, 2] }]),
//   header4: fluidSpacingFunc([{ property: 'margin', values: [1, 0, 0.5] }]),
//   header5: fluidSpacingFunc([{ property: 'margin', values: [0.5, 0, 0.125] }]),
//   instagram: '0',
// }

const TypographyBlock = styled(Typography)`
  ${media.mq1`
    ${({ type }) => typeLookupMapMq1[type]};
  `};
  ${media.mq2`
    ${({ type }) => typeLookupMapMq1[type]};
  `};
  ${media.mq3`
    ${({ type }) => typeLookupMapMq1[type]};
  `};
  ${media.mq4`
    ${({ type }) => typeLookupMapMq1[type]};
  `};
`

export default TypographyBlock
