import React from 'react'
import { graphql } from 'gatsby'

import Layout from '~components/Layout'
import { toMultiline, removeLinebreak } from '~utils/helpers'
import { NAVIGATION } from '~utils/constants'
// import TagList from '~components/TagList'

import './queries'
import Wrapper from './Wrapper'
import Category from './Category'
import Header from './Header'
import Divider from './Divider'
import ReleaseDate from './ReleaseDate'
import HeroImage from './HeroImage'
import Container from './Container'
import PageBody from './PageBody'
import PostLinks from './PostLinks'

const TemplateBlogPost = ({ data, pageContext, location }) => {
  const {
    title,
    content,
    category,
    publishDateISO,
    heroImage,
    slug,
    description: { description },
    // tags,
  } = data.contentfulBlogPost

  const previous = pageContext.prev
  const next = pageContext.next

  const metaTags = {
    title: removeLinebreak(title),
    description,
    slug: `${NAVIGATION.BLOG.BLOG}/${slug}`,
    image: {
      url: heroImage.imageLarge[0].image.id,
      alt: heroImage.altText,
    },
    blogPostSEO: true,
  }

  return (
    <Layout pathname={location.pathname} metaTags={metaTags}>
      <Container>
        {/* {tags && <TagList tags={tags} />} */}
        <Wrapper>
          <Category as="h2" type="header5" align="center" uppercase>
            {category}
          </Category>
          <Header as="h1" type="header2" align="center" uppercase>
            {toMultiline(title)}
          </Header>
          <Divider />
          <ReleaseDate>gepostet am {publishDateISO}</ReleaseDate>
        </Wrapper>
        <HeroImage imageData={heroImage} />
        <PageBody content={content} />
        <PostLinks previous={previous} next={next} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query(
    $slug: String!
    $locale: String!
    $smallContentWidth: Int!
    $mediumContentWidth: Int!
    $maxContentWidth: Int!
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      category
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(
        locale: $locale
        formatString: "DD. MMMM YYYY"
      )
      description {
        description
      }
      ...HeroImage
      ...Content
    }
  }
`

export default TemplateBlogPost
