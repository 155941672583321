import styled from 'styled-components'

import { media } from '~styles/mediaQuery'

const Wrapper = styled.div`
  ${media.mq1`
    margin: ${({ theme }) => theme.spacing[16]} 0 ${({ theme }) =>
    theme.spacing[16]};
  `};
  ${media.mq234`
    margin: ${({ theme }) => theme.spacing[32]} 0 ${({ theme }) =>
    theme.spacing[16]};
  `};
`

export default Wrapper
