import styled from 'styled-components'
import { Link } from 'gatsby'

import stylingLink from './stylingLink'

const LinkInternal = styled(Link)`
  ${stylingLink};
`

export default LinkInternal
